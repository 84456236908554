<!-- Renovação de sessão do Acesso Cidadão -->
<ng-container *ngIf="shouldWaitForAcessoCidadaoRenewal; else mainTemplate">
    <div class="lds-dual-ring"></div>
    <div class="acesso-cidadao-renewing">
        <p><b>Aguarde enquanto sua sessão do Acesso Cidadão é renovada...</b></p>
        <span>Esta janela fechará automaticamente quando a renovação for concluída</span>
    </div>
</ng-container>

<ng-template #mainTemplate>
    <div class="working-area" *ngIf="flowDefinitions != null">
        <!-- Barra de tarefas -->
        <p-toolbar>
            <!-- Ações -->
            <div class="ui-toolbar-group-left">
                <!-- Novo Fluxo -->
                <div class="rounded-circle d-flex align-items-center justify-content-center fab-btn new-button"
                     featureHint="newFlowDefinition"
                     matTooltip="Novo Fluxo"
                     matTooltipPosition="left"
                     (click)="newFlowDefinition()"
                >
                    <i class="fas fa-plus"></i>
                    <span class="new-tag">NOVO</span>
                </div>

                <!-- Caixa de Fluxos -->
                <div class="form-inline select-container splitter pr-2" featureHint="flowInbox" featureHintHAlign="right">
                    <label>Caixa de Fluxos</label>

                    <ngx-select-dropdown [(ngModel)]="selectedFlowInboxObject"
                                         [config]="selectedFlowInboxConfig"
                                         [options]="getOrderedAdminUnitsList()"
                                         (change)="changeFlowInbox()"
                    ></ngx-select-dropdown>
                </div>

                <!-- Contador -->
                <div class="flow-counter pl-2">
                    {{filteredFlowDefinitions.length > 0 ? filteredFlowDefinitions.length : 'Nenhum'}}
                    Fluxo{{filteredFlowDefinitions.length > 1 ? 's' : ''}}
                    encontrado{{filteredFlowDefinitions.length > 1 ? 's' : ''}}
                </div>
            </div>

            <!-- Filtros -->
            <div class="ui-toolbar-group-right" featureHint="filterBar">
                <!-- Publicados -->
                <mat-form-field>
                    <mat-label>Publicados</mat-label>
                    <mat-chip-list [multiple]="false">
                        <mat-chip matTooltip="Apenas Fluxos com a última versão publicada" [selected]="isPublishedFilterOn" (click)="toggleIsPublished(true)">
                            <i class="icon fas fa-check"></i>
                        </mat-chip>
                        <mat-chip matTooltip="Ambos" [selected]="!isPublishedFilterOn && !isPublishedFilterOff" (click)="toggleIsPublished()">
                            <i class="icon fas fa-minus"></i>
                        </mat-chip>
                        <mat-chip matTooltip="Apenas Fluxos com a última versão não publicada" [selected]="isPublishedFilterOff" (click)="toggleIsPublished(false)">
                            <i class="icon fas fa-times"></i>
                        </mat-chip>
                    </mat-chip-list>
                </mat-form-field>

                <!-- Ativos -->
                <mat-form-field>
                    <mat-label>Ativos</mat-label>
                    <mat-chip-list [multiple]="false">
                        <mat-chip matTooltip="Apenas ativos" [selected]="isActiveFilterOn" (click)="toggleIsActive(true)">
                            <i class="icon fas fa-check"></i>
                        </mat-chip>
                        <mat-chip matTooltip="Ambos" [selected]="!isActiveFilterOn && !isActiveFilterOff" (click)="toggleIsActive()">
                            <i class="icon fas fa-minus"></i>
                        </mat-chip>
                        <mat-chip matTooltip="Apenas inativos" [selected]="isActiveFilterOff" (click)="toggleIsActive(false)">
                            <i class="icon fas fa-times"></i>
                        </mat-chip>
                    </mat-chip-list>
                </mat-form-field>

                <!-- Customizado -->
                <ng-container>
                    <input type="text" class="form-inline" placeholder="Filtrar pelo nome do Fluxo" (change)="runFilter()" (keyup)="0" #inputFilterRef />
                    <button type="button" class="btn btn-outline-danger clear" matTooltip="Limpar filtro por nome do Fluxo" (click)="clearFilter()">
                        <i class="fas fa-times"></i>
                    </button>
                </ng-container>
            </div>
        </p-toolbar>

        <!-- Lista de Fluxos -->
        <div class="cards-container">
            <ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>

            <ng-container *ngIf="isBootstrapFinished">
                <!-- Lista vazia -->
                <ng-container *ngIf="filteredFlowDefinitions.length == 0">
                    <div class="card empty">Nenhum item a exibir</div>
                </ng-container>

                <div class="cards">
                    <ng-container *ngFor="let context of flowDefinitionContexts">
                        <!-- Agrupamento -->
                        <div class="flow-definition-context" *ngIf="hasFilteredFlowDefinition(context)" #contextRef>
                            <ngx-spinner [name]="context.id" class="flow-definition-item" bdColor="rgba(255, 255, 255, 1)" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>

                            <!-- Editar -->
                            <i class="fas fa-edit edit-context" featureHint="flowDefinitionContext|Name" #editContextButtonRef
                               *ngIf="shouldShowEditContext(context)"
                               matTooltipPosition="above"
                               matTooltip="Editar nome do Agrupamento"
                               (click)="toggleEditContext(context)"
                            ></i>

                            <!-- Nome do Agrupamento -->
                            <ng-container>
                                <input type="text" class="form-inline" placeholder="Nome do Agrupamento" maxlength="50" #editContextInputRef
                                       *ngIf="shouldShowEditContextInput && currentEditContextId == context.id"
                                       [(ngModel)]="context.name"
                                       (keyup.enter)="toggleEditContext(context)"
                                       (change)="contextNameChange()"
                                />
                            </ng-container>

                            <!-- Fechar Modo Agrupamento -->
                            <button type="button" class="btn btn-secondary exit-context" *ngIf="shouldShowExitContextButton(context)"
                                    (click)="exitContextMode()"
                            >Fechar</button>

                            <ng-container *ngFor="let flow of context.flowDefinitions">
                                <!-- Fluxo -->
                                <flow-definition-item *ngIf="isFilteredFlowDefinition(flow)" featureHint="flowDefinitionItem" featureHintVAlign="top" #flowItemRef
                                                      [inputModel]="flow"
                                                      [inputIsContextMode]="isContextMode"
                                                      [inputIsLastContextIndex]="flow.contextIndex == getContextFlowDefinitions(flow.contextId).length - 1"
                                                      [inputHasReachedMaxLimit]="getContextFlowDefinitions(contextModeDispatcher?.contextId).length == CONTEXT_MAX_LIMIT"
                                                      [inputHasFilteredFlowItemInContext]="hasFilteredFlowItemInContext(context.id)"
                                                      [inputContextModeDispatcherId]="contextModeDispatcher?.id"
                                                      [inputDispatcherContextId]="contextModeDispatcher?.contextId"
                                                      [style.visibility]="isFlowItemVisible ? 'visible' : 'hidden'"
                                                      (outputActionEvent)="redirectToAction($event)"
                                ></flow-definition-item>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
